import React from 'react'
import ContentPage from '~/components/Content/Page'
import Metadata from '~/components/Metadata'
import { getAnnouncementBar, getPage } from 'graphql-cms/queries'
import nextContentWrapper from 'graphql-cms/nextContentWrapper'
import type { InferNextPage } from '~/types'

export const getStaticProps = nextContentWrapper.getStaticProps(async () => {
  return {
    props: {
      page: await getPage({ slug: 'home' }),
      announcementBarConfig: await getAnnouncementBar(),
    },
  }
})

const HomePage: InferNextPage<typeof getStaticProps> = ({ page }) => {
  return (
    <>
      <Metadata title={page.name} />
      <ContentPage page={page} />
    </>
  )
}

export default HomePage
